import React, {  useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import styles from './TempLinkPopup.module.css';
import TextField from '../TextField';
import { Typography } from '@material-ui/core';
import { ReactComponent as CalendarIcon } from '../../assets/images/icons/calendar.svg'
import Calendar from './Calendar';


const ShareLinkPopup = ({ open, setOpen, userID, resumeId }) => {
  const [email, setEmail] = useState("");
  const [isValid, setValid] = useState(false)
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(() => new Date(new Date().setDate(new Date().getDate() + 1)));

  const onClose = () => {
    setOpen(false);
    setEmail("");
  }

  const handleChange = (e) => {
    const email = e;
    setEmail(email);
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(email)) {
      setValid(true);
    } else {
      setValid(false);
    }
  };

  return (
      <Dialog
      style={{overflow:"visible"}}
      open={open}
      classes={{
        root: styles.popupRoot,
        paper: styles.popupContainer,
      }}
      BackdropProps={{
        classes: {
          root: styles.backDrop,
        },
      }}
      keepMounted
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description">
      <DialogTitle className='text-sm'
        classes={{
          root: styles.popupTitle,
        }}
        id="alert-dialog-slide-title">Share temporary link</DialogTitle>
      <DialogContent
      style={{overflow:"visible"}}
        classes={{
          root: styles.popupText,
        }}
      >
        <div className='flex flex-col gap-2'>
          <Typography style={{fontSize: 10.5, color: "#718096", fontWeight: 600, letterSpacing: '0.26px', textTransform:"uppercase"}}>Recipient email*</Typography>
          <TextField
            value={email}
            onChange={handleChange}
            placeholder={"email@example.com"}
          />
        </div>
        <div onClick={(e)=>{e.stopPropagation(); setCalendarOpen(!calendarOpen)}} style={{marginTop: 16, cursor: "pointer",padding: "10px 14px", display:"flex", gap: 4, alignItems:"center", width:"fit-content", borderRadius: 3, border: "1px solid #EDF2F7", position:"relative"}}>
          <Typography style={{ fontWeight: 600, fontSize: 14, color:"#505051" }}>Active till:</Typography>
          <Typography style={{ fontSize: 14, color:"#505051" }}>{selectedDate.toLocaleDateString('ru-RU', { day: '2-digit', month: '2-digit', year: 'numeric' })}</Typography>
          <CalendarIcon style={{marginLeft: 10}}/>
        </div>
        {calendarOpen && <div style={{position:'fixed', top: '55%', zIndex: 9999}}>
            <Calendar open={calendarOpen} setOpen={setCalendarOpen} selectedDate={selectedDate} setSelectedDate={setSelectedDate}/>
        </div>}
      </DialogContent>
      <DialogActions
        classes={{
          root: styles.popupControls,
        }}
      >
        <Button onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={onClose} disabled={!isValid}>
          Share link
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ShareLinkPopup