/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef, useEffect, useContext, Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PanZoom } from 'react-easy-panzoom';
import { connect } from 'react-redux';
import './styles.css';

import AppContext from '../../context/AppContext';
import PageContext from '../../context/PageContext';

import LeftSidebar from '../LeftSidebar/LeftSidebar';

import templates from '../../templates';
import PageController from '../../shared/PageController';
import PrintDialog from '../../shared/PrintDialog';

// eslint-disable-next-line import/no-named-as-default
import Header from '../../shared/header/Header';

import { changeLoggedIn } from '../../actions';
import RightSidebar from '../RightSidebar/RightSidebar';

export const App = () => {
  const pageRef = useRef(null);
  const panZoomRef = useRef(null);
  const { i18n } = useTranslation();

  const [scaleValue, setScaleValue] = useState(1);
  const [lastCvUpdate, setLastCvUpdate] = useState(1);

  const context = useContext(AppContext);
  const { state, dispatch } = context;
  const { theme, settings, isAdmin, isSale, data } = state;

  const [showTab, setShowTab] = useState(!(isAdmin || isSale));

  const pageContext = useContext(PageContext);
  const { setPageRef, setPanZoomRef } = pageContext;

  useEffect(() => {
    setPageRef(pageRef);
    setPanZoomRef(panZoomRef);
    i18n.changeLanguage(settings.language);
  }, [dispatch, setPageRef, setPanZoomRef, i18n, settings.language]);

  const onStateChange = () => {
    if (panZoomRef.current) setScaleValue(panZoomRef.current.state.scale);
  };

  const calcColsNumberCenter = () => {
    let result = '';
    if ((isAdmin || isSale)) result = 'isRightbar';
    return result;
  };
  const colsNumberCenter = calcColsNumberCenter();
  const colsNumberLeftSideBar = showTab ? 'col-span-6' : 'col-span-1';

  return (
    <Suspense fallback="Loading...">
      <div className="h-screen grid grid-cols-21 items-center">
        <div className={`${colsNumberLeftSideBar} z-10 ${showTab ? "showtab" : ''} LeftSidebar-container`}>
          <LeftSidebar showTab={showTab} setShowTab={setShowTab} />
        </div>
        <div className={`z-10 ${colsNumberCenter} ${showTab ? "showtab" : ''} centercontainer h-screen overflow-auto`}>
          <Header setLastCvUpdate={setLastCvUpdate} />
          <div className="flex justify-center items-center">
            <PanZoom
              ref={panZoomRef}
              minZoom="0.4"
              maxZoom="1"
              autoCenter
              autoCenterZoomLevel={1}
              enableBoundingBox
              boundaryRatioVertical={0.8}
              boundaryRatioHorizontal={0.8}
              style={{ outline: 'none' }}
              disabled
              onStateChange={onStateChange}
            >
              <div id="page" ref={pageRef} className="shadow-2xl break-words mb-4">
                {templates.find((x) => theme.layout.toLowerCase() === x.key).component()}
              </div>
            </PanZoom>
            <PageController scaleValue={scaleValue} />
          </div>
        </div>
        <div id="printPage" className="break-words">
          {templates.find((x) => theme.layout.toLowerCase() === x.key).component()}
        </div>
        {isAdmin || isSale ? (
          <div className={`${showTab ? "showtab" : ''} rightsidebar z-10`}>
            <RightSidebar lastCvUpdate={lastCvUpdate} />
          </div>
        ) : null}
        <PrintDialog
          firstName={data?.summary?.firstName || ''}
          lastName={data?.summary?.lastName || ''}
          position={data?.summary?.position || ''}
        />
      </div>
    </Suspense>
  );
};

export default connect(
  (state) => ({
    loggedIn: state.loggedIn,
  }),
  {
    changeLoggedIn,
  },
)(App);