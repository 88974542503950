import React from 'react';
import ReactDOM from 'react-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import configureStore, { history } from './configureStore';
import { BrowserRouter } from 'react-router-dom';

import Routes from './routes';

import './i18n';
import './assets/tailwind/tailwind.css';
import './index.css';

import * as serviceWorker from './serviceWorker';
import { AppProvider } from './context/AppContext';
import { PageProvider } from './context/PageContext';

const store = configureStore();

toast.configure({
  autoClose: 10000,
  closeButton: false,
  hideProgressBar: true,
  position: toast.POSITION.BOTTOM_RIGHT,
});

ReactDOM.render(
  <Provider store={store}>
    <AppProvider>
      <PageProvider>
        <ConnectedRouter history={history}>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </ConnectedRouter>
      </PageProvider>
    </AppProvider>
  </Provider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
